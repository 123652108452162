import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource
} from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { FormaAutorizacionesComponent} from '../forma/forma.component';
import { Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Empleado } from 'src/app/entidades/empleado';
import { Autorizaciones } from 'src/app/entidades/autorizaciones';
import { TipoOpcion } from 'src/app/entidades/tipoOpcion';

@Component({
  selector: 'app-catalogo-autorizaciones',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoAutorizacionesComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;

  fuenteDatos: MatTableDataSource<Autorizaciones> = new MatTableDataSource([]);
  columnasMostradas = ['nombre', 'correo', 'opcion', 'nivel', 'activo', 'opciones'];
  cargando = true;

  rowExapandido: Empleado | null = null;

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length === 0) {
      return 50;
    }
    return 100;
  }

  

  subsArticulos: Subscription;
  subsActivar: Subscription;
  subsDesactivar: Subscription;
  subsGuardar: Subscription;
  constructor(
    public ventana: MatDialog,
    private ctx: Contexto,
    private alerta: ServicioAlerta
  ) { }

  ngOnInit() {
    this.cargarAutorizaciones();
    
  }

  cargarAutorizaciones(): void {
    this.cargando = true;
    this.ctx.autorizaciones.obtenerTodos().subscribe(autorizaciones => {
      this.fuenteDatos = new MatTableDataSource(autorizaciones);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
      this.cargando = false;
    });
  }

  cambiarEstado(activo: boolean, id: number): void {
    if (activo) {
      this.activar(id);
    } else {
      this.desactivar(id);
    }
  }

  activar(id: number): void {
    this.subsActivar = this.ctx.autorizaciones.activar(id).subscribe(
      () => {
        this.alerta.mostrarExito('Autorizacion activada');
      },
      (error) => {
        this.alerta.mostrarError('Error al activar');
      }
    );
  }

  desactivar(id: number): void {
    this.subsActivar = this.ctx.autorizaciones.desactivar(id).subscribe(
      () => {
        this.alerta.mostrarExito('Autorizacion desactivada');
      },
      (error) => {
        this.alerta.mostrarError('Error al desactivar');
      }
    );
  }

  abrirForma(id?: number): void {
    const dato = id ? id : null;
    const forma = this.ventana.open(FormaAutorizacionesComponent, {
      data: dato,
      panelClass: 'form-container'
    });

    this.subsGuardar = forma.componentInstance.guardado.subscribe(() => {
      //this.cargarArticulos();
    });
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  eliminar(id: number): void {
    this.subsActivar = this.ctx.empleados.eliminar(id.toString()).subscribe(
      () => {
        this.alerta.mostrarExito('Registro eliminado');
        //this.cargarArticulos();
      },
      error => {
        this.alerta.mostrarError('Error al eliminar');
      }
    );
  }

  ngOnDestroy(): void {
    if (this.fuenteDatos) {
      this.fuenteDatos.disconnect();
    }
    if (this.subsArticulos) {
      this.subsArticulos.unsubscribe();
    }
    if (this.subsActivar) {
      this.subsActivar.unsubscribe();
    }
    if (this.subsDesactivar) {
      this.subsDesactivar.unsubscribe();
    }
    if (this.subsGuardar) {
      this.subsGuardar.unsubscribe();
    }
  }

}
