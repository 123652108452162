import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { ServicioOverlayCargando } from 'src/app/utilerias/overlay-cargando.service';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { first } from 'rxjs/operators';
import { Autenticacion } from 'src/app/autenticacion/autenticacion.service';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as moment from 'moment';
import * as _ from 'underscore';
import { Partidas } from 'src/app/entidades/partidas';
import { PartidasCerradasDataComponent } from './partidas-cerradas-data/partidas-cerradas-data.component';
import { PartidasCerradasSelecComponent } from './partidas-cerradas-selec/partidas-cerradas-selec.component';

@Component({
    selector: 'app-grafica-partidas-cerradas',
    templateUrl: './partidas-cerradas.component.html',
    styleUrls: ['./partidas-cerradas.component.scss']
})
export class PartidasCerradasComponent implements OnInit, OnChanges {
    @Input() year: number = (new Date()).getFullYear();
    partidas: Partidas[];
    partidasF: Partidas[];


    public barChartOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
            yAxes: [{
                ticks: {
                    max: 100,
                    callback: (value, index, values) => {
                        return value;
                    }
                }
            }]
        },
        legend: {
            position: 'bottom',
            labels: {
                filter: function name(item, chart) {
                    return !item.text.includes('Servicios');
                },
            }
        },
        plugins: {
            datalabels: {
                formatter: (value, ctx) => {
                    const label = ctx.chart.data.labels[ctx.dataIndex];
                    return label;
                }
            }
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    return tooltipItem.yLabel;
                }
            }
        },
        onClick: (event, active) => this.chartClicked({ event, active })
    };

    public barChartLabels = [];
    public barChartData: ChartDataSets[] = [
        { data: [], label: 'Ganadas', type: 'line', lineTension: 0, datalabels: { display: false }, fill: false, pointRadius: 1 },
        { data: [], label: 'Perdidas', type: 'line', lineTension: 0, datalabels: { display: false }, fill: false, pointRadius: 1 },
    ];

    public barChartType: ChartType = 'bar';
    public barChartLegend = true;
    public barChartPlugins = [pluginDataLabels];
    public barChartColors = [
        {
            backgroundColor: []
        }
    ];


    constructor(private ctx: Contexto, public overlay: ServicioOverlayCargando, public autenticacion: Autenticacion, public ventana: MatDialog) {
        this.cargarDatos();
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(`Child OnChages: ${this.year}`);
        if (this.partidas) {
            this.actualizarDatos();
        }
    }

    ngOnInit() { }

    // events
    public chartClicked({ event, active }: { event: MouseEvent; active: {}[]; }): void {
        debugger
        console.log(event, active);
        if (active.length > 0) {
            const chart = active[0]['_chart'];
            const columnName = chart.data.labels[chart.getElementAtEvent(event)[0]['_index']];
            const colNum = active[0]['_index'];
            const rastreos = _.chain(this.partidasF)
                .filter(c => moment(c.fechaCotizacion).locale('es-mx').format('MMMM') == columnName)
            this.ventana.open(PartidasCerradasSelecComponent, {
                data: { rastreos: rastreos.value(), columnName, colNum },
                panelClass: 'form-container',
                width: '50vw',
            });
        }
    }

    public chartHovered({ event, active }: { event: MouseEvent; active: {}[]; }): void {
        console.log(event, active);
    }

    cargarDatos(): void {
        this.ctx.documentosVenta
            .obtenerPartidas()
            .pipe(first())
            .subscribe((response) => {
                this.partidas = response.servicios;
                this.actualizarDatos();
            });
    }


    actualizarDatos() {

        this.partidasF = this.partidas.filter(c => moment(c.fechaCotizacion).format('YYYY') && moment(c.fechaCotizacion).format('YYYY') == String(this.year));

        const months = _.chain(this.partidasF)
            .groupBy(c => moment(c.fechaCotizacion).format('MM'))
            .map((x, y) => {
                return {
                    monthNumber: y,
                    monthName: `${moment(y, 'MM').locale('es-mx').format('MMMM')}`,
                    data: x
                }
            })
            .sortBy(c => Number(c.monthNumber));


        const monthValuesG = months.map(c => c.data)
            .map(c => {
                return (c.filter(d => d.estatus == "Ganada"))
            }).value();

        const monthValuesP = months.map(c => c.data)
            .map(c => {
                return (c.filter(d => d.estatus == "Perdida"))
            }).value();

        const monthKeys = months.map(c => c.monthName).value();

        const ganadas = 'Ganadas';
        const perdidas = 'Perdidas';

        this.barChartLabels = monthKeys;

        this.barChartData.find(c => c.label == ganadas).label;
        this.barChartData.find(c => c.label == perdidas).label;


        this.barChartData.find(c => c.label == 'Ganadas').data = monthValuesG.map(c => Number(c.length));
        this.barChartData.find(c => c.label == 'Perdidas').data = monthValuesP.map(c => Number(c.length));

        this.barChartData.find(c => c.label == perdidas).backgroundColor = '#DE0000';
        this.barChartData.find(c => c.label == perdidas).borderColor = '#DE0000';
        this.barChartData.find(c => c.label == perdidas).pointBackgroundColor = '#DE0000';
        this.barChartData.find(c => c.label == perdidas).pointHoverBackgroundColor = '#DE0000';
        this.barChartData.find(c => c.label == perdidas).pointHoverBorderColor = '#DE0000';

        this.barChartData.find(c => c.label == ganadas).backgroundColor = '#0070C0';
        this.barChartData.find(c => c.label == ganadas).borderColor = '#0070C0';
        this.barChartData.find(c => c.label == ganadas).pointBackgroundColor = '#0070C0';
        this.barChartData.find(c => c.label == ganadas).pointHoverBackgroundColor = '#0070C0';
        this.barChartData.find(c => c.label == ganadas).pointHoverBorderColor = '#0070C0';

    }

    abrirForma(): void {
        const forma = this.ventana.open(PartidasCerradasDataComponent, {
            data: { rastreos: this.partidasF },
            panelClass: 'form-container',
            width: '40vw'
        });
    }

}