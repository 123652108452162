import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { Contexto } from 'src/app/api/contexto.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import { Moneda } from 'src/app/entidades/moneda';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Factura } from 'src/app/entidades/factura';
import { ConstructorFormaFactura } from './constructor-forma.service';
import {
    DetalleFactura,
    DetalleCostosAdicionales,
} from 'src/app/entidades/detalle-factura';
import { ValidadorFormas } from 'src/app/utilerias/validadorFormas';
import { ParametrosDocumentoFactura } from './parametros';
import { ParametrosDocumentoFacturaService } from './parametros.service';
import { Documento } from 'src/app/entidades/documento';
import { CategoriasDocumento } from 'src/app/enumeraciones/enumeraciones';
import { ModalTiposDocumentosNoFacturarComponent } from 'src/app/modulos/flujo-ventas/componentes/modal-tipos-documento-no-facturar/modal-tipos-documento-no-facturar.component';
import { first } from 'rxjs/operators';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';
import Swal from 'sweetalert2';
import { ProyectoExterno } from 'src/app/entidades/proyecto-externo';

@Component({
    selector: 'app-documento-factura',
    templateUrl: './documento-factura.component.html',
    styleUrls: ['./documento-factura.component.scss'],
    providers: [ConstructorFormaFactura],
})
export class DocumentoFacturaComponent implements OnInit {
    cargando = false;

    venta = false;

    monedas: Observable<Moneda[]>;

    proyectosExternosFiltrados: ProyectoExterno[];

    banderaGeneral: boolean;
    @Output() bandera = new EventEmitter<boolean>(); //

    forma: FormGroup;
    get formasDetalle(): FormArray {
        return <FormArray>this.forma.get('detalles');
    }

    get parametros(): ParametrosDocumentoFactura {
        return this.servicioParametros.parametros;
    }

    constructor(
        private servicioParametros: ParametrosDocumentoFacturaService,
        private ctx: Contexto,
        private dialogoRef: MatDialogRef<DocumentoFacturaComponent>,
        private cf: ConstructorFormaFactura,
        private alerta: ServicioAlerta,
        private validadorForma: ValidadorFormas,
        private ventanaModal: MatDialog,
    ) {
        this.forma = cf.crearFormaFactura();
    }

    ngOnInit() {
        const factura = this.convertirDocumentoAFactura(
            this.parametros.documento
        );
        this.cf.asignarFacturaAForma(this.forma, factura);

        if (this.parametros.documento.categoriaDocumentoId === CategoriasDocumento.CotizacionVenta) {
            this.venta = true;
        }


        var proy = this.parametros.documento.proyectoFolio.split("-");

        
        this.proyectosExternosFiltrados = this.parametros.proyectosExternos.filter(e => e.socioNegocio == proy[1]);
    }

    convertirDocumentoAFactura(documento: Documento): Factura {
        const factura: Factura = {
            monedaAbreviatura: documento.monedaAbreviatura,
            procesado: -1,
            proyectoExternoId: documento.proyectosExternosId,
            documentoId: documento.id,
            folioDocumento: documento.folio,
            folioProyecto: documento.proyectoFolio,
            folioSv: documento.folioSv,
            folioRe: documento.folioRe,
            fechaRegistro: new Date(),
            id: 0,
            socioId: documento.proveedorId,
            socioNombreCorto: documento.clienteNombreCorto,
            total: documento.total,
            procesar: true,
            descripcion: '',
            errorMensaje: '',
            fechaEnvio: null,
            detalles: documento.servicios.map((s) => {
                const detalle: DetalleFactura = {
                    id: 0,
                    articuloDescripcion: '',
                    articuloId: null,
                    facturaId: null,
                    texto: s.descripcion,
                    total: s.total,
                    retencionId: s.retencionId,
                    trasladoId: s.trasladoId,
                    importeMoneda: s.importeMoneda,
                    cantidad: s.cantidad != 0 ? s.cantidad : 1,
                    tieneCostos: s.costosAdicionales.length > 0 ? false : true,
                    costos: s.costosAdicionales.map((x) => {
                        const costosAdiciones: DetalleCostosAdicionales = {
                            // id: x.id,
                            cantidad: x.cantidad,
                            servicioId: x.servicioId,
                            articuloId: x.articuloId,
                            articuloDescripcion: x.articuloDescripcion,
                            precio: x.precio,
                            total: x.precio,
                        };
                        return costosAdiciones;
                    }),
                };
                return detalle;
            }),
        };
        return factura;
    }

    cerrar(guardoFactura: boolean): void {
        // this.bandera.emit(guardoFactura);
        this.dialogoRef.close(guardoFactura);
    }

    async guardar(procesar: boolean): Promise<void> {
        if (this.forma.valid) {
            this.cargando = true;
            const factura = {} as Factura;
            Object.assign(factura, this.forma.value);
            factura.procesar = procesar;
            factura.fechaRegistro = new Date().toISOString();
            // factura.descripcion = this.forma.controls.;
            let respuesta: Promise<void>;
            if (
                this.parametros.documento.categoriaDocumentoId ===
                CategoriasDocumento.CotizacionCostos
            ) {
    
                //VALIDO PROYECTO EXTERNO DE LA VENTA
                await this.ctx.documentosFactura.validoProyectoExternoCV(factura)
                    .toPromise()
                    .then(() => {
                        respuesta = this.ctx.documentosFactura
                            .guardarCompra(factura)
                            .toPromise()
                            .then(async () => {
                                this.alerta.mostrarExito('Datos guardados');
                                let intentos = 0;
                                let exportada = false;

                                while (intentos <= 2) {
                                    if (await this.ctx.documentosCompra.enviadaSAP(factura.documentoId) && !exportada) {
                                        await this.exportarOrdenesCompra(factura.documentoId);
                                        exportada = true;
                                        intentos = 3;
                                    }
                                    intentos++;
                                }
                                this.cargando = false;
                                this.bandera.emit(true);
                                this.cerrar(true);
                            },
                                (e) => {
                                    console.log(e);
                                    this.cargando = false;
                                    this.alerta.mostrarError('Ocurrio un error');
                                    this.bandera.emit(false);
                                    console.log(this.bandera);
                                });
                    },
                        (e) => {
                            this.alerta.mostrarError("El proyecto de la venta no coincide, favor de verificar.")
                        }
                    );

            } else {
                //VALIDO PROYECTO EXTERNO DE LA COMPRA
                await this.ctx.documentosFactura.validoProyectoExternoCT(factura)
                    .toPromise()
                    .then(() => {
                        this.ventanaModal
                            .open(ModalTiposDocumentosNoFacturarComponent, {
                                data: {
                                    proyectoId: this.parametros.documento.proyectoId,
                                    documentoId: this.parametros.documento.id,
                                    folio: this.parametros.documento.folio,
                                    //estadofacturacionId: this.parametros.estadosFacturacion
                                },
                                panelClass: 'form-container',
                            })
                            .afterClosed()
                            .pipe(first())
                            .subscribe((resp: Boolean) => {
                                if (resp == true) {
                                    respuesta = this.ctx.documentosFactura
                                        .guardarVenta(factura)
                                        .toPromise()
                                        .then(() => {
                                            this.alerta.mostrarExito('Datos guardados');
                                            this.cerrar(true);
                                            this.cargando = false;
                                            this.bandera.emit(true);
                                        },
                                            (e) => {
                                                console.log(e);
                                                this.cargando = false;
                                                this.alerta.mostrarError('Ocurrio un error');
                                                this.bandera.emit(false);
                                                console.log(this.bandera);
                                            }
                                        );
                                } else {
                                    this.cargando = false;
                                }
                            });

                    },
                        (e) => {
                            this.alerta.mostrarError("El proyecto de la venta no coincide, favor de verificar.")
                        }
                    );

            }
        } else {
            this.alerta.mostrarAdvertencia('Los datos son inválidos.');
            this.validadorForma.marcarComoTocado(this.forma);
            this.bandera.emit(false);
        }
    }



    async exportarOrdenesCompra(id: number) {
        try {
            const documento = await this.ctx.documentosCompra
                .obtenerDocumentosCompraMigracionSingle(id)
                .toPromise();
            console.log('documento-->', documento);

            await this.ctx.exportacion
                .migracionOrdenesCompra(documento)
                .toPromise()
                .then((compras) => {
                    this.alerta.mostrarExito(
                        'Orden de compra exportada con éxito'
                    );
                })
                .catch((e) => {
                    console.log(e);
                    this.alerta.mostrarError(
                        'Error al exportar la orden de compra'
                    );
                });
        } catch (error) {
            console.log(error);
            this.alerta.mostrarError('Error interno!');
        }
    }
}
