import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RepositorioBase } from './repositorio-base';
import { DescripcionSV } from '../entidades/descripcionSV';
import { EstatusClienteDocumentoVenta } from '../entidades/estatus-cliente-documento-venta';
import { Articulo } from '../entidades/articulo';

export abstract class RepositorioCatalogo<T> extends RepositorioBase {

    constructor(clienteHttp: HttpClient, controlador: string) {
      super(clienteHttp, controlador);
    }

    obtener(id: number): Observable<T> {
        return this.clienteHttp.get(`${this.ruta}/${id}`).pipe(
            map((data: T) => {
                return data;
            })
        );
    }

    // obtenerArt(id: number): Observable<T> {
    //     return this.clienteHttp.get(this.ruta +'/ObtenerArt' + '/' + id).pipe(
    //         map((data: T) => {
    //             return data;
    //         })
    //     );
    // }

    obtenerArt(id: number): Observable<Articulo[]> {
        let ruta = this.ruta + "/ObtenerArt/" + id;
        return this.clienteHttp.get(ruta)
            .pipe(map((data: Articulo[]) => {
                return data;
            }));
    }

    obtenerCV(id: number): Observable<T> {
        return this.clienteHttp.get(`${this.ruta}/${id}`).pipe(
            map((data: T) => {
                return data;
            })
        );
    }

    obtenerTodos(): Observable<T[]> {
        return this.clienteHttp.get(this.ruta).pipe(
            map((data: T[]) => {
                return data;
            })
        );
    }

    obtenerActivos(tipo: string): Observable<T[]> {
        return this.clienteHttp.get(this.ruta + '/obtenerActivos/'+ tipo).pipe(
            map((data: T[]) => {
                return data;
            })
        );
    }

    obtenerTodosActivos(): Observable<T[]> {
        return this.clienteHttp.get(this.ruta + '/ObtenerTodosActivos').pipe(
            map((data: T[]) => {
                return data;
            })
        );
    }

    obtenerJefes(): Observable<T[]> {
        return this.clienteHttp.get(this.ruta + '/ObtenerJefes').pipe(
            map((data: T[]) => {
                return data;
            })
        );
    }

    obtenerTodosCT(id: number): Observable<T[]> {
        return this.clienteHttp.get(this.ruta + '/ObtenerTodosCT/' + id ).pipe(
            map((data: T[]) => {
                return data;
            })
        );
    }

    obtenerUltimoEstatusCV(id: number): Observable<DescripcionSV> {
        return this.clienteHttp.get(`${this.ruta}/ObtenerUltimoEstatusCV/${id}`).pipe(
            map((data: DescripcionSV) => {
                return data;
            })
        );
    }

    ObtenerEstatusPorCV(id: number): Observable<EstatusClienteDocumentoVenta[]> {
        return this.clienteHttp.get(`${this.ruta}/ObtenerEstatusPorCV/${id}`).pipe(
            map((data: EstatusClienteDocumentoVenta[]) => {
                return data;
            })
        );
    }

    obtenerTodosCV(id: number): Observable<T[]> {
        return this.clienteHttp.get(this.ruta + '/ObtenerTodosCV/' + id ).pipe(
            map((data: T[]) => {
                return data;
            })
        );
    }

    guardar(entidad: T): Observable<T> {
        return this.clienteHttp.post(this.ruta, entidad).pipe(
            map((data: T) => {
                return data;
            })
        );
    }

    actualizar(id: string, entidad: T): Observable<T> {
        const ruta = this.ruta + '/' + id;
        console.log('entidad-->', entidad);
        return this.clienteHttp.put(ruta, entidad).pipe(
            map((data: T) => {
                return data;
            })
        );
    }

    eliminar(id: string): Observable<boolean> {
        const ruta = this.ruta + '/' + id;
        return this.clienteHttp.delete(ruta).pipe(
            map((data: T) => {
                return true;
            })
        );
    }
}
